<template>
  <div class="columns">
    <div class="column is-6-tablet is-4-widescreen is-offset-3-tablet is-offset-4-widescreen">
      <div class="card" style="overflow: visible">
        <div class="card-content">
          <form @submit.prevent="onLogin" autocomplete="off">
            <div class="has-text-centered" v-if="false">
              <figure class="image is-128x128 mx-auto">
                <img src="@/assets/logo.svg" alt="Logo Alfalab">
              </figure>
            </div>
            <b-field
                :type="errors.username?'is-danger':''"
                :label="$t('common.attributes.username')"
                :message="errors.username"
            >
              <b-input
                  v-model="data.username"
                  @input="onSearchBranchOffice"
                  placeholder="Clave de usuario"
                  :loading="loading"
                  autofocus
              ></b-input>
            </b-field>

            <b-field
                :type="errors.password?'is-danger':''"
                :label="$t('common.attributes.password')"
                :message="errors.password"
            >
              <b-input
                  v-model="data.password"
                  type="password"
                  password-reveal
                  :placeholder="$t('common.placeholders.password')"
              ></b-input>
            </b-field>
            <select-branch-office v-model="data.branch_office"
                                  :data="branchOffices"
                                  :loading="loading"
                                  :searchable="branchOffices.length > 4"
                                  :disabled="! data.username">
            </select-branch-office>
<!--            <b-field>-->
<!--              <b-checkbox v-model="data.rememberMe">{{ $t('auth.remember') }}</b-checkbox>-->
<!--            </b-field>-->

            <div class="field">
              <button class="button is-primary is-rounded is-fullwidth"
                      :disabled="! canLogin">
                {{ $t('auth.login.action') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import SelectBranchOffice from "../../components/brach-office/SelectBranchOffice";

export default {
  name: "Login",
  components: {SelectBranchOffice},
  data() {
    return {
      data: {
        rememberMe: false,
        branch_office: 0,
      },
      loading: false,
      branchOffices: [],
      errors: {},
      in_process: false
    };
  },
  computed: {
    canLogin() {
      return !!this.data.username && !!this.data.password && !!this.data.branch_office;
    }
  },
  beforeRouteLeave(to, from, next) {
    next();
  },
  methods: {
      async onLogin() {
          if (!this.in_process) {
              this.$loader.show();
              this.in_process = true;
              await this.$http.get('../sanctum/csrf-cookie');
              this.errors = {};
              this.$auth.login({
                  data: this.data,
                  success({data}) {
                      this.$loader.hide();
                      this.in_process = false;
                      this.$toast.success(data.message);
                      this.$store.commit('setMainData', data.meta);
                      this.$store.commit('setLoggedIn', true);
                      this.$store.commit('order/setMedical', data.meta.medical)
                      this.$store.dispatch('fetchSpecialities');
                      this.getCatalogs();
                      //this.getSpecialOffers();
                      //console.log(this.$store.state.order.medical);
                  },
                  error({response = {}}) {
                      this.$loader.hide();
                      let data = response.data || {};
                      this.errors = data.errors || {};
                      this.$toast.error(data.message);
                      this.in_process = false;
                  }
              });
          }
      },
      onSearchBranchOffice: debounce(function (val) {
          if (val === '') {
              return
          }
          this.data.branch_office = null;
          this.errors = {};
          this.loading = true;
          this.$http.get(`auth/branch-office/${val}`)
              .then(({data}) => {
                  this.loading = false;
                  this.branchOffices = data.data || [];
                  if (this.branchOffices.length > 1) {
                      this.data.branch_office = this.branchOffices.find(el => el.suggest === true).id;

                  } else {
                      this.data.branch_office = this.branchOffices[0].id;
                  }
              })
              .catch(({response}) => {
                  this.loading = false;
                  let data = response.data || {};
                  this.errors.username = data.message;
              });
      }, 1000),
      getCatalogs() {
          this.$http.get('/sat-regimenes').then(({data}) => {
              this.$store.commit('setSatRegimenes', data.data);
          })
      },
      getSpecialOffers() {
          this.$http.get('/special-promo').then(({data}) => {
              this.$store.commit('setSpecialPromo', data.data);
          })
      }
  }
}
</script>

<style scoped>

</style>
